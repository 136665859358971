import {Col, Drawer, Row, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {CustomDispatch} from '../../../helpers';
import {getTodaysLogsRequest} from '../../../redux/slicers/form';
import {Icons} from '../../../theme';
import './styles.scss';
import {ArrowRight} from 'lucide-react';
import {useLocation, useNavigate} from 'react-router-dom';
import {replaceValInString} from '../../../utils';
import {FORM_ROUTE} from '../../../constants';
const NotificationDrawer = () => {
  //STATES
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  //REDUX DATA
  const {todaysLogs} = useSelector(state => state.form);
  const {data} = useSelector(state => state.user);

  //CUSTOM DISPATCH
  const [getTodaysLogs, isLoading] = CustomDispatch(getTodaysLogsRequest);

  //CUSTOM COMPONENTS
  const statusIndicator = status => {
    let bgColor =
      status == 'Not Started'
        ? '#F8D7DA'
        : status == 'Incomplete'
        ? '#FFF3CD'
        : '#D4EDDA';
    let textColor =
      status == 'Not Started'
        ? '#721C24'
        : status == 'Incomplete'
        ? '#856404'
        : '#155724';
    if (status == '') return null;
    return (
      <div
        style={{backgroundColor: bgColor, color: textColor}}
        className="w-max p-1 !rounded-lg text-[10px] whitespace-nowrap">
        {status}
      </div>
    );
  };

  //CONST VALS
  const location = useLocation();
  const navigate = useNavigate();
  const columns = [
    {
      title: 'Procedure Name',
      dataIndex: 'form_name',
      render: text => <p className="font-table max-w-full">{text}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '100px',
      render: text => statusIndicator(text),
    },
    {
      title: 'Due Time',
      dataIndex: 'due_time',
      width: '100px',
      render: text => <p className="font-table">{text ?? '-----'}</p>,
    },
    {
      title: '',
      width: '60px',
      render: data => (
        <ArrowRight
          className="cursor-pointer"
          color="#004078"
          absoluteStrokeWidth
          onClick={() => {
            console.log('Data on Click', data);
            //navigate(replaceValInString(FORM_ROUTE, {':id': data.form_key}));
            navigate(
              replaceValInString(
                data.optional ? '/optional-form/:id' : FORM_ROUTE,
                {
                  ':id': data.form_key,
                },
              ),
            );
            isDrawerOpenHandler();
          }}
        />
      ),
    },
  ];

  //HANDLERS
  const isDrawerOpenHandler = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  //HOOKS
  useEffect(() => {
    if (data?.id) getTodaysLogs();
  }, [data, location]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  useEffect(() => {
    if (todaysLogs) {
      let temp = todaysLogs.filter(log => log.status !== 'Complete');
      setTableData(temp);
    }
  }, [todaysLogs]);

  return (
    <div>
      <div className="relative flex items-center justify-center">
        {tableData.length > 0 && (
          <div className="select-none absolute w-[12px] h-[12px] top-0 right-0 bg-[#DE350B] flex items-center justify-center text-[8px] text-[#fff] rounded-full">
            {tableData.length}
          </div>
        )}
        <Icons.AlertIcon
          className="cursor-pointer header-icons"
          height={24}
          width={24}
          onClick={isDrawerOpenHandler}
        />
      </div>
      <Drawer
        width={680}
        open={isDrawerOpen}
        closeIcon={false}
        mask={false}
        zIndex={1}
        destroyOnClose
        className="common-drawer"
        // onClose={handleClose}
        // maskClosable
      >
        <Row gutter={[0, 15]} className="mt-[69px]">
          <Col span={24}>
            <div className="flex items-center justify-between ">
              <p className="font-h4">Active Procedures</p>
              <Icons.CloseIcon
                height={16}
                width={16}
                onClick={isDrawerOpenHandler}
                className="cursor-pointer"
              />
            </div>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{y: 600, x: 530}}
              className="active-procedures-table"
              loading={isLoading}
            />
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};
export default NotificationDrawer;
