import React, {useState, useEffect} from 'react';
import {Icons} from '../../../theme';

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  const formatTime = time => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return time.toLocaleDateString('en-US', options);
  };

  const getHour = time => {
    return String(time.getHours()).padStart(2, '0');
  };

  const getMinute = time => {
    return String(time.getMinutes()).padStart(2, '0');
  };

  const getSecond = time => {
    return String(time.getSeconds()).padStart(2, '0');
  };

  const getWeekDay = time => {
    const options = {weekday: 'long'};
    return time.toLocaleDateString('en-US', options);
  };

  const getMonth = time => {
    const options = {month: 'long'};
    return time.toLocaleDateString('en-US', options);
  };

  const getDate = time => {
    return time.getDate();
  };

  const getYear = time => {
    return time.getFullYear();
  };

  return (
    <div className="bg-white-100 justify-self-center pr-[30px] py-[3px] pl-[20px] flex items-center justify-start border-[1px]  border-solid border-grey-200 rounded-[20px]">
      <p className="font-arial font-semibold text-base text-textColor-100 mr-[30px]">
        {getWeekDay(currentTime)},
      </p>
      <p className="font-arial font-semibold text-base text-textColor-100 mr-[10px]">
        {getMonth(currentTime)},&nbsp;&nbsp; {getDate(currentTime)}{' '}
        {getYear(currentTime)}
      </p>
      <div className="flex items-center justify-start gap-[5px]">
        <Icons.ClockIcon height={15} width={15} />
        <p className="font-arial font-semibold text-base text-textColor-100">
          {getHour(currentTime)}:{getMinute(currentTime)}:
          {getSecond(currentTime)}
        </p>
      </div>
    </div>
  );
};

export default Clock;
