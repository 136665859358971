import {createSlice} from '@reduxjs/toolkit';

const OptionalReducer = createSlice({
  name: 'optionalSteps',
  initialState: {
    optionalSteps: [],
  },
  reducers: {
    setOptionalSteps: (state, action) => {
      console.log('Action Payload:', action.payload);

      if (Array.isArray(action.payload)) {
        // Handle array case (existing logic)
        console.log('first');
        action.payload.forEach(number => {
          if (!state.optionalSteps.includes(number)) {
            state.optionalSteps.push(number);
          }
        });
      } else if (typeof action.payload === 'number') {
        // Handle single number case (existing logic)
        if (!state.optionalSteps.includes(action.payload)) {
          state.optionalSteps.push(action.payload);
        } else {
          state.optionalSteps = state.optionalSteps.filter(
            num => num !== action.payload,
          );
        }
      } else if (
        typeof action.payload === 'object' &&
        action.payload !== null
      ) {
        // Handle { stepNumber, isAdding } payload
        const {stepNumber, isAdding} = action.payload;

        if (isAdding) {
          // Add stepNumber if not already present
          if (!state.optionalSteps.includes(stepNumber)) {
            state.optionalSteps.push(stepNumber);
          }
        } else {
          // Remove stepNumber if it exists
          state.optionalSteps = state.optionalSteps.filter(
            num => num !== stepNumber,
          );
        }
      } else {
        console.warn('Unexpected payload type:', typeof action.payload);
      }
    },

    blankOptionalSteps: state => {
      state.optionalSteps = [];
    },
  },
});

export const {setOptionalSteps, blankOptionalSteps} = OptionalReducer.actions;

export default OptionalReducer.reducer;
