import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Collapse,
} from 'antd';
import {PlusCircleTwoTone} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from 'react';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {CustomDispatch} from '../../../helpers';
import {
  getFormUiRequest,
  getPayloadRequest,
  submitFormRequest,
} from '../../../redux/slicers/form';
import {
  getAuthorizedTechniciansRequest,
  getMachineStatusRequest,
  getMdlValuesRequest,
  getReasonCodesRequest,
  getRNsRequest,
  getSaltLevelsRequest,
  getShiftsRequest,
  getStatusRequest,
  getTechniciansRequest,
  getTotalChlorineValuesRequest,
  getWaterSoftenerValuesRequest,
} from '../../../redux/slicers/general';
import {Icons} from '../../../theme';
import {filterFirstArray, inputFieldRule, toastAlert} from '../../../utils';
import Loader from '../../loader';
import './styles.scss';
import OTPSelect from '../OtpSelect';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {ALERT_TYPES} from '../../../constants';
import {X} from 'lucide-react';
import {useDispatch} from 'react-redux';
import {updateTodaysLogRequest} from '../../../redux/slicers/form';
import {ConfirmationModal} from '../../../components';
import {
  blankOptionalSteps,
  setOptionalSteps,
} from '../../../redux/slicers/optionalSteps';

const {Panel} = Collapse;
const OptionalFormComponent = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  //STATES
  const [completedSteps, setCompletedSteps] = useState([]);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);
  const [isStepCompleted, setIsStepCompleted] = useState(false); // New flag
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [disabledKeys, setDisabledKeys] = useState([]);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [bulkValues, setBulkValues] = useState({});

  //CONST VALS
  const [form] = Form.useForm();
  const [bulkForm] = Form.useForm();
  const navigate = useNavigate();
  const {id} = useParams();

  //REFS
  const payloadRef = useRef({});

  //CUSTOM DISPATCH
  const [getTechnicians, techniciansLoader] = CustomDispatch(
    getTechniciansRequest,
  );
  const [getRNs, rnLoader] = CustomDispatch(getRNsRequest);
  const [getShifts, shiftsLoader] = CustomDispatch(getShiftsRequest);
  const [getPayload, payloadLoader] = CustomDispatch(getPayloadRequest);
  const [submitForm, submitFormLoader] = CustomDispatch(submitFormRequest);
  const [getFormUi, uiLoader] = CustomDispatch(getFormUiRequest);
  const [getTotalChlorineValues, totalChlorineValuesLoader] = CustomDispatch(
    getTotalChlorineValuesRequest,
  );
  const [getWaterSoftenerValues, waterSoftenerValuesLoader] = CustomDispatch(
    getWaterSoftenerValuesRequest,
  );
  const [getMdlResultValues, mdlValuesLoader] =
    CustomDispatch(getMdlValuesRequest);
  const [getReasonCodes, reasonCodesLoader] = CustomDispatch(
    getReasonCodesRequest,
  );
  const [getSaltLevels, saltLevelsLoader] =
    CustomDispatch(getSaltLevelsRequest);
  const [getMachineStatus, machineStatusLoader] = CustomDispatch(
    getMachineStatusRequest,
  );
  const [getStatus, statusLoader] = CustomDispatch(getStatusRequest);
  const [getAuthorizedTechnicians, authorizedTechniciansLoader] =
    CustomDispatch(getAuthorizedTechniciansRequest);

  const [updateLogStatus, updateLogStatusLoader] = CustomDispatch(
    updateTodaysLogRequest,
  );
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  //REDUX DATA
  const {
    technicians,
    rnList,
    totalChlorineValues,
    waterSoftenerValues,
    mdlValues,
    reasonCodes,
    saltLevels,
    machineStatus,
    status,
    shifts,
    authorizedTechnicians,
  } = useSelector(state => state.general);
  const {formPayload} = useSelector(state => state.form);
  const {formsData, isOnline, formsUi} = useSelector(state => state.offline);

  //Optional Work
  const [optionalSecAdded, setOptionalSecAdded] = useState(false);
  const optionalSteps = useSelector(state => state.optionalSteps.optionalSteps);

  const getFormPayloadKeys = obj => {
    return Object.keys(obj);
  };
  const payloadKeys = getFormPayloadKeys(formPayload);

  const formSchema = useSelector(state =>
    isOnline
      ? state.form.formUiJson || state.offline.formsUi?.[id]
      : state.offline.formsUi?.[id],
  );

  const formSchemaKeys = formSchema?.steps
    ?.filter(step => step.optional === true) // Filter steps where optional is true
    ?.map(step => step.key);

  const commonKeys = payloadKeys.filter(key => formSchemaKeys?.includes(key));

  const commonKeyIds = formSchema?.steps
    ?.filter(step => commonKeys.includes(step.key)) // Filter matching steps
    ?.map(step => step.id.toString()); // Convert IDs to strings

  const extractedNumbers = commonKeys
    .map(key => {
      const match = key.match(/\d+$/); // Match digits at the end
      return match ? parseInt(match[0], 10) : null;
    })
    .filter(num => num !== null); // Remove nulls if any

  // Dispatch only once on initial render
  useEffect(() => {
    if (extractedNumbers.length > 0) {
      dispatch(setOptionalSteps(extractedNumbers)); // Dispatch action
      if (!optionalSecAdded) {
        setOptionalSecAdded(true); // Enable validation for optional steps
      }
    }
  }, [dispatch, extractedNumbers]);

  const userData = useSelector(state => state.user.data);

  const isBulkEntryForm =
    formSchema?.procedure_title?.toLowerCase()?.includes('machine') ||
    formSchema?.procedure_title?.toLowerCase()?.includes('station');

  //HANDLERS
  // Open and close modal handlers
  const showBulkModal = () => {
    setFirstLoad(false);
    setIsBulkModalOpen(true);
  };
  const handleCancel = () => {
    setIsBulkModalOpen(false);
    setBulkValues({});
    let bulkFormKeys = Object.keys(bulkForm.getFieldsValue());
    setDisabledKeys(prevDisabledKeys =>
      prevDisabledKeys.filter(key => !bulkFormKeys.includes(key)),
    );
    bulkForm.resetFields();
  };
  const handleBulkSubmit = async () => {
    if (selectedMachines.length === 0) {
      toastAlert('Please select at least one machine', ALERT_TYPES.ERROR);
      return;
    }
    try {
      // Validate all fields in the bulk form
      const values = await bulkForm.validateFields();

      const updatedValues = {};

      // Iterate over only the selected machines
      selectedMachines.forEach(machineKey => {
        const step = formSchema?.steps?.find(step => step.key === machineKey);

        if (step) {
          const stepPayload = {};

          // Process each procedure and its fields for the selected step
          step?.procedures?.forEach(procedure => {
            procedure?.fields?.forEach(field => {
              const bulkValue = values[field.label]; // Use `label` to find bulk value
              // if (bulkValue !== undefined) {
              if (field.type === 'checkbox' && bulkValue === undefined) {
                stepPayload[field.key] = 'False'; // Store the value using `key
              } else {
                if (bulkValue !== undefined) {
                  stepPayload[field.key] = bulkValue; // Store the value using `key`
                }
              }
              // }
            });
          });

          // Add a `completedOn` timestamp for the step if it has any data
          if (Object.keys(stepPayload).length > 0) {
            stepPayload.completedOn = new Date().toISOString();
            updatedValues[machineKey] = stepPayload;
          }
        }
      });

      // Use the fillObject method to update the form values
      const filledFormValues = fillObject(updatedValues, formSchema);

      form.setFieldsValue(filledFormValues); // Update the form with filled values

      setIsBulkModalOpen(false); // Close the modal
      bulkForm.resetFields();
      setSelectedMachines([]);
      handleFormValuesChange();
    } catch (error) {
      // Optionally, show an error message to the user
      toastAlert(
        'Please fill in all required fields before submitting.',
        ALERT_TYPES.ERROR,
      );
    }
  };

  const removeSelectedMachine = machineKey => {
    const updatedMachines = selectedMachines.filter(key => key !== machineKey);

    // Update the selected machines state
    setSelectedMachines(updatedMachines);
  };
  const handlePrint = () => {
    window.print();
  };

  const confirmationModalOpenHandler = () => {
    setConfirmationModalOpen(!confirmationModalOpen);
  };

  console.log('Formshema Steps', formSchema?.steps);
  const checkCompletedSteps = async () => {
    await createPayload(); // Ensure the payload is created first
    const values = form.getFieldsValue();
    const completed = formSchema?.steps
      ?.map(step => {
        const isStepCompleted = step.procedures.every(procedure => {
          return procedure.fields.every(field => {
            const disabled = disabledKeys.includes(field.key);
            //if (disabled) return true; // line added
            if (field.type === 'checkbox') {
              return field?.rules?.isRequired ? values[field.key] : true;
              // return  values[field.key];
              // return true;
            } else if (field.type === 'number') {
              const value = values[field.key];

              // Skip validation for non-required or disabled fields
              if (
                field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
              ) {
                return true;
              }
              //if (!field?.rules?.isRequired) return true; // line added and above is commented

              // Check basic number field validations (minValue, maxValue)
              const basicValidation =
                value !== undefined &&
                value !== '' &&
                (field?.rules?.minValue
                  ? value >= field?.rules?.minValue
                  : true) &&
                (field?.rules?.maxValue
                  ? value <= field?.rules?.maxValue
                  : true);

              // Handle dependency validation if applicable
              if (field?.rules?.dependency?.type === 'comparison') {
                const otherFieldValue =
                  values[field.rules?.dependency?.target_field]; // Get value of the other field
                const dependencyValue = field.rules?.dependency?.value; // Get the value to compare the difference against

                if (
                  value === undefined ||
                  value === null ||
                  value === '' || // Skip if value is empty
                  otherFieldValue === undefined ||
                  otherFieldValue === null ||
                  otherFieldValue === '' ||
                  otherFieldValue === 0 || // Skip if other field value is invalid
                  dependencyValue === undefined // Skip if dependency value is missing
                ) {
                  return true;
                }

                const difference = Math.abs(value - otherFieldValue); // Calculate the absolute difference

                // Handle conditions based on dependency
                switch (field.rules?.dependency?.condition) {
                  case 'difference_less_than':
                    return difference <= dependencyValue;

                  case 'difference_greater_than':
                    return difference >= dependencyValue;

                  case 'equals_to':
                    return difference === dependencyValue;

                  case 'delta':
                    return difference <= dependencyValue;

                  default:
                    return true; // Invalid condition, assume valid
                }
              }

              // If no dependency, rely on basic validation
              return basicValidation;
            } else if (field.type === 'textarea') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
                ? true
                : value !== undefined &&
                    value !== '' &&
                    !/^\s*$/.test(value) &&
                    value.length >= 10 &&
                    value.length <= 150;
            } else if (field.type === 'select') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
                ? true
                : value !== undefined && value !== '';
            } else if (field.type === 'time') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
                ? true
                : value !== undefined && value !== '' && dayjs(value).isValid();
            } else if (field.type === 'switch') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
                ? true
                : value !== undefined;
            } else if (field.type === 'date') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
                ? true
                : value;
            } else if (field.type === 'number') {
              const value = values[field.key];
              return field?.rules?.isRequired === false ||
                (formSchema?.is_sequential == 'false' ? disabled : false)
                ? true
                : value !== undefined && value !== '';
            } else {
              // For headings and other types, consider them as always completed
              return true;
            }
          });
        });
        return isStepCompleted ? step.number : null;
      })
      .filter(step => step !== null);

    // Determine new steps that are now completed
    let newCompletion = _.difference(completed, []);

    // Create a copy of the existing payload
    let newPayload = {...payload};

    // Update payload and timestamp only for newly completed steps
    newCompletion.forEach(stepNumber => {
      const step = formSchema?.steps?.find(s => s.number === stepNumber);
      if (!step) return;

      let stepPayload = {...newPayload[step.key]} || {};
      step?.procedures?.forEach(procedure => {
        procedure?.fields?.forEach(field => {
          if (field.key && field.type !== 'heading' && field.type !== 'text') {
            stepPayload[field.key] =
              field?.type == 'number'
                ? values[field.key]?.includes('.')
                  ? values[field.key]?.split?.[1]?.length <= 2
                    ? parseFloat(values[field.key]).toFixed(2) // Ensure it's treated as a number
                    : values[field.key] // Return original if more than 2 decimals
                  : parseFloat(values[field.key]).toFixed(2)
                : field.type == 'time'
                ? values[field.key]
                  ? dayjs(values[field.key]).format('hh:mm A')
                  : undefined
                : field.type == 'date'
                ? values[field?.key]
                  ? dayjs(values[field.key])
                      // .tz(process.env.REACT_APP_TIME_ZONE)
                      .format('YYYY-MM-DD')
                  : undefined
                : field.type == 'switch'
                ? values[field.key]
                  ? 'True'
                  : 'False'
                : field.type == 'checkbox'
                ? values[field.key] !== undefined
                  ? values[field.key]
                  : '' // Assign empty string if undefined
                : values[field.key];
          }
        });
      });

      let date = new Date().toISOString();
      if (
        Object.entries(stepPayload).filter(([_, value]) => value !== undefined)
          .length > 0
      ) {
        stepPayload.completedOn =
          payload?.[step?.key]?.completedOn ||
          formPayload?.[step?.key]?.completedOn
            ? payload?.[step?.key]?.completedOn ||
              formPayload?.[step?.key]?.completedOn
            : dayjs(date).tz(process.env.REACT_APP_TIME_ZONE).format(); // This will keep the correct timezone offset
      }
      if (
        Object?.entries(stepPayload).filter(
          ([_, value]) => value !== undefined && value !== '',
        ).length > 0
      ) {
        newPayload[step.key] = stepPayload;
      }
    });

    // Update the payload once after all iterations
    setPayload(newPayload);

    // Set the new completed steps
    setCompletedSteps(completed);

    // Check if any field is filled
    const anyFieldFilled = Object.values(values).some(
      value => value !== undefined && value !== false && value !== '',
    );
    setIsAnyFieldFilled(anyFieldFilled);

    // Set the flag to indicate a step has been completed
    if (newCompletion.length > 0) {
      setIsStepCompleted(true);
    }
  };

  const createPayload = (preserve = true) => {
    let newPayload = preserve ? {...payload} : {}; // Preserve existing payload
    formSchema?.steps?.forEach(step => {
      let stepPayload = {...newPayload[step.key]} || {};

      step?.procedures?.forEach(procedure => {
        procedure?.fields?.forEach(field => {
          if (field.type !== 'heading' && field.type !== 'text') {
            if (!stepPayload[field.key]) {
              stepPayload[field.key] = undefined; // Initialize if not already present
            }
          }
        });
      });
      if (
        Object.entries(stepPayload).filter(([_, value]) => value !== undefined)
          .length > 0
      ) {
        stepPayload['completedOn'] = formPayload?.[step?.key]?.completedOn
          ? formPayload?.[step?.key]?.completedOn
          : stepPayload['completedOn'] || ''; // Preserve existing timestamp
      }
      if (
        Object.entries(stepPayload).filter(([_, value]) => value !== undefined)
          .length > 0
      ) {
        newPayload[step.key] = stepPayload;
      }
    });
    setPayload(newPayload);
    return newPayload;
  };

  let timeoutId;

  const handleFormValuesChange = () => {
    clearTimeout(timeoutId); // Clear any existing timeout

    timeoutId = setTimeout(() => {
      handleValuesChange();
    }, 2000); // Set new timeout for 3 seconds
  };
  const evaluateDependencies = (changedValues, allValues, isBulk) => {
    const newDisabledKeys = new Set(disabledKeys); // Clone existing disabled keys
    const updatedValues = {...allValues}; // Clone allValues for updates
    const disableTracker = {}; // Track reasons to disable fields

    formSchema?.steps?.forEach(step => {
      step.procedures.forEach(procedure => {
        procedure.fields.forEach(field => {
          const {dependency} = field.rules || {};
          if (dependency && dependency.action === 'disable') {
            const {target_field, condition, value, action} = dependency;

            // Determine the field name based on isBulk
            const fieldName = isBulk ? field.label : field.key;
            const fieldValue = allValues?.[fieldName];

            // Evaluate the dependency condition
            const isConditionMet = (() => {
              switch (condition) {
                case 'equals':
                  return value.includes(fieldValue);
                case 'not_equals':
                  return !value.includes(fieldValue);
                case 'greater_than':
                  return fieldValue > value[0];
                case 'less_than':
                  return fieldValue < value[0];
                default:
                  return false;
              }
            })();

            // Flatten all fields in the current step to search for target fields
            const allFieldsInStep = step.procedures.flatMap(p => p.fields);
            console.log(isBulk, 'isBulk');
            // Apply the action (disable/enable) on target fields if the condition is met
            target_field.forEach(target => {
              // Find the target field in the entire step
              const targetField = allFieldsInStep.find(f => f.key === target);
              const targetName = isBulk ? targetField?.label || target : target;

              if (action === 'disable') {
                if (isConditionMet) {
                  disableTracker[targetName] = true; // Mark the field to be disabled
                  updatedValues[targetName] = undefined; // Clear its value
                } else if (!disableTracker[targetName]) {
                  disableTracker[targetName] = false; // Mark the field to remain enabled unless overridden
                }
              }
            });
          }
        });
      });
    });

    // Finalize the disabled keys based on the tracker
    Object.keys(disableTracker).forEach(key => {
      if (disableTracker[key]) {
        newDisabledKeys.add(key);
      } else {
        newDisabledKeys.delete(key);
      }
    });
    const allLabels =
      formSchema?.steps?.[0]?.procedures?.flatMap(procedure =>
        procedure?.fields?.map(field => field.label),
      ) || [];

    const newDisabledKeysArray = filterFirstArray(
      Array.from(newDisabledKeys),
      allLabels,
    );

    // Update state and form
    if (!isBulk) {
      setDisabledKeys(newDisabledKeysArray); // Update disabled keys state
    } else {
      setDisabledKeys(Array.from(newDisabledKeys)); // Update disabled keys state
    }
    form.setFieldsValue(updatedValues); // Update form values
    bulkForm.setFieldsValue(updatedValues); // Update form values
  };
  const parseValues = fieldsArray => {
    if (!fieldsArray) {
      return;
    }
    return fieldsArray.reduce((acc, field) => {
      if (field.name) {
        acc[field.name[0]] = field.value; // Use the first item in the name array as the key
      }
      return acc;
    }, {});
  };
  const handleValuesChange = (changedFields, allFields) => {
    const changedValues = parseValues(changedFields); // Convert changedFields to key-value pairs
    const allValues = parseValues(allFields); // Convert allFields to key-value pairs
    evaluateDependencies(
      changedValues,
      allValues ?? form.getFieldsValue(),
      false,
    );
    if (!uiLoader && !payloadLoader && !techniciansLoader) {
      checkCompletedSteps();
    }
  };
  const handleBulkFieldsChange = (changedFields, allFields) => {
    // if (!isBulkModalOpen) return; // Skip if the modal is closed
    const changedValues = parseValues(changedFields); // Convert changedFields to key-value pairs
    const allValues = parseValues(allFields); // Convert allFields to key-value pairs

    evaluateDependencies(changedValues, allValues, isBulkModalOpen);
  };
  const onSelectChange = value => {
    setFirstLoad(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const fillObject = (payload, json) => {
    const filledObject = {};
    const steps = Object.keys(payload);
    json?.steps?.forEach(step => {
      if (steps?.includes(step.key)) {
        step.procedures.forEach(procedure => {
          procedure.fields.forEach(field => {
            if (field.name) {
              if (payload[step.key][field.key] !== undefined) {
                filledObject[field.key] =
                  field.type === 'time'
                    ? dayjs(payload[step.key][field.key], 'hh:mm A')
                    : field.type === 'date'
                    ? payload[step.key][field.key]
                      ? dayjs(payload[step.key][field.key], 'YYYY-MM-DD')
                      : undefined
                    : field.type === 'select'
                    ? payload[step.key][field.key]
                    : // ? payload[step.key][field.key]
                    // : field?.rules?.default
                    field.type === 'switch'
                    ? payload[step.key][field.key] === 'True'
                      ? true
                      : false
                    : field.type === 'checkbox'
                    ? payload[step.key][field.key] === 'True' ||
                      payload[step.key][field.key] === true
                      ? true
                      : false
                    : payload[step.key][field.key];
              }
            }
          });
        });
      }
    });
    return filledObject;
  };

  const handleEdit = () => {
    console.log(
      'ID of form',
      formSchema.procedure_name,
      formSchema.procedure_title,
    );
    setConfirmationModalOpen(true);
  };

  const handleUpdateLogStatus = () => {
    // console.log(formSchema, 'formSchema?.form_key');

    updateLogStatus({
      pathParams: formSchema?.procedure_name,
      logic() {
        toastAlert(
          `${formSchema?.procedure_title} status updated to incomplete`,
        );
        setConfirmationModalOpen(false);
        setIsFormCompleted(false);
        // to-do : refresh the page/change its disable state
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (formSchema) {
      form.resetFields();
      checkCompletedSteps(); // Initial check
    }
  }, [form, formSchema]);

  useEffect(() => {
    getTechnicians();
    getRNs();
    getShifts();
    getPayload({
      pathParams: id,
      logic(res) {
        if (res?.response_code === '205') {
          setIsFormCompleted(true);
        }
        if (res?.error === 'Form not found') {
          navigate('/');
        }
      },
    });
    getTotalChlorineValues();
    getWaterSoftenerValues();
    getMdlResultValues();
    getReasonCodes();
    getSaltLevels();
    getMachineStatus();
    getStatus();
    getAuthorizedTechnicians();
  }, []);
  const handleSetFieldsValue = fields => {
    form.setFieldsValue(fields); // Update the form fields
    const allFields = form.getFieldsValue(true); // Get all form fields
    const changedFields = Object.keys(fields).map(key => ({
      name: [key],
      value: fields[key],
    })); // Structure changed fields
    // handleValuesChange(changedFields, allFields); // Manually trigger handleValuesChange
  };
  useEffect(() => {
    if (
      (isOnline ? formPayload : formsData?.[id]) &&
      !payloadLoader &&
      JSON.stringify(formSchema) !== '' &&
      !techniciansLoader
    ) {
      form.setFieldsValue(
        fillObject(isOnline ? formPayload : formsData?.[id], formSchema),
      );
      form.validateFields();
      handleValuesChange();
    }
  }, [
    formPayload,
    payloadLoader,
    formSchema,
    uiLoader,
    technicians,
    techniciansLoader,
  ]);

  useEffect(() => {
    if (formsData?.[id]?.submitted && !isOnline) {
      setIsFormCompleted(true);
    }
  }, [formsData, isOnline]);

  useEffect(() => {
    const isPayloadChanged =
      JSON.stringify(payloadRef.current) !== JSON.stringify(payload);
    if (isStepCompleted && !payloadLoader && !firstLoad && isPayloadChanged) {
      submitForm({
        payload: {...payload, Submitted: false},
        pathParams: id,
        logic: () => {
          payloadRef.current = payload;
        },
      });
      setIsStepCompleted(false); // Reset the flag after submission
    }
  }, [payload, isStepCompleted, payloadLoader]);

  useEffect(() => {
    getFormUi({pathParams: id});
  }, []);
  //CUSTOM COMPONENTS
  const renderField = (field, isBulk) => {
    const disabled = disabledKeys.includes(isBulk ? field.name : field.key);
    switch (field.type) {
      case 'checkbox':
        return (
          <Form.Item
            name={isBulk ? field.label : field.key}
            valuePropName="checked"
            key={field.name}>
            <Checkbox disabled={disabled} onChange={() => setFirstLoad(false)}>
              {field.label}
            </Checkbox>
          </Form.Item>
        );
      case 'number':
        return (
          <Form.Item
            dependencies={[field?.rules?.dependency?.target_field]}
            rules={[
              ...inputFieldRule({
                name: field.name,
                isWhiteSpace: false,
                ...field.rules,
                isRequired: isBulk,
              }),
              {
                validator: (_, value) => {
                  // Skip validation if dependency type is not 'comparison'
                  if (field?.rules?.dependency?.type !== 'comparison') {
                    return Promise.resolve();
                  }

                  // Skip validation if value is empty (only validate after entry)
                  if (value === undefined || value === null || value === '') {
                    return Promise.resolve();
                  }

                  const otherFieldValue = form.getFieldValue(
                    field.rules?.dependency.target_field,
                  ); // Get value of the other field
                  if (
                    otherFieldValue === undefined ||
                    otherFieldValue === null ||
                    otherFieldValue === '' ||
                    otherFieldValue === 0
                  ) {
                    return Promise.resolve();
                  }
                  const dependencyValue = field.rules?.dependency.value; // Get the value to compare the difference against

                  // Ensure otherFieldValue and dependencyValue exist
                  if (
                    otherFieldValue === undefined ||
                    otherFieldValue === null ||
                    dependencyValue === undefined
                  ) {
                    return Promise.resolve();
                  }

                  const difference = Math.abs(value - otherFieldValue); // Calculate the absolute difference

                  // Handle conditions based on dependency
                  switch (field.rules?.dependency.condition) {
                    case 'difference_less_than':
                      if (difference <= dependencyValue) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `The difference must be less than ${dependencyValue}.`,
                        ),
                      );

                    case 'difference_greater_than':
                      if (difference >= dependencyValue) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `The difference must be greater than ${dependencyValue}.`,
                        ),
                      );

                    case 'equals_to':
                      if (difference === dependencyValue) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `The difference must equal ${dependencyValue}.`,
                        ),
                      );

                    case 'delta':
                      if (difference <= dependencyValue) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `The difference must not exceed ${dependencyValue} (±delta).`,
                        ),
                      );

                    default:
                      return Promise.reject(new Error('Invalid condition.'));
                  }
                },
              },
            ]}
            name={isBulk ? field.label : field.key}
            key={field.name}
            onKeyPress={e => {
              const charCode = e.charCode || e.keyCode;
              const char = String.fromCharCode(charCode);

              // Check if the pressed key is an alphabet character
              if (!/^[0-9.]$/.test(char)) {
                e.preventDefault(); // Ignore other characters
              }
            }}>
            <Input
              className="custom-input text-center"
              type="number"
              placeholder={field?.placeholder}
              disabled={disabled}
            />
          </Form.Item>
        );

      case 'textarea':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: true,
              isMaxLength: true,
              isMinLength: true,
              maxLength: 150,
              minLength: 10,
              ...field.rules,
            })}
            name={isBulk ? field.label : field.key}
            key={field.name}>
            <TextArea
              className="custom-input"
              placeholder={field?.placeholder}
              maxLength={150}
              minLength={10}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 'select':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              isRequired: isBulk ? !disabled : false,
              ...field.rules,
            })}
            name={isBulk ? field.label : field.key}
            key={field.name}
            style={{width: '100%'}}>
            {(() => {
              const isSpecialField =
                field.url?.includes('shifts') ||
                field.url?.includes('total-chlroine') ||
                field.url?.includes('water-softener') ||
                field.url?.includes('get/result') ||
                field.url?.includes('get/reason-code') ||
                field.url?.includes('get/salt-level') ||
                field.url?.includes('machine_status') ||
                field.url?.includes('status');
              const isAuthorizedField = field.url?.includes('authorized');

              const selectOptions = isSpecialField
                ? field.url?.includes('total-chlroine')
                  ? totalChlorineValues
                  : field.url?.includes('water-softener')
                  ? waterSoftenerValues
                  : field.url?.includes('shifts')
                  ? shifts
                  : field.url?.includes('get/result')
                  ? mdlValues
                  : field.url?.includes('get/reason-code')
                  ? reasonCodes
                  : field.url?.includes('get/salt-level')
                  ? saltLevels
                  : field.url?.includes('machine_status')
                  ? machineStatus
                  : status
                : isAuthorizedField
                ? rnList
                : technicians;

              const selectLoading = isSpecialField
                ? field.url?.includes('total-chlroine')
                  ? totalChlorineValuesLoader
                  : field.url?.includes('water-softener')
                  ? waterSoftenerValuesLoader
                  : field.url?.includes('shifts')
                  ? shiftsLoader
                  : field.url?.includes('get/result')
                  ? mdlValuesLoader
                  : field.url?.includes('get/reason-code')
                  ? reasonCodesLoader
                  : field.url?.includes('get/salt-level')
                  ? saltLevelsLoader
                  : field.url?.includes('machine_status')
                  ? machineStatusLoader
                  : statusLoader
                : isAuthorizedField
                ? rnLoader
                : techniciansLoader;
              return isSpecialField || !isOnline ? (
                <Select
                  allowClear
                  // showSearch
                  optionFilterProp="children"
                  onChange={onSelectChange}
                  filterOption={filterOption}
                  placeholder={field?.placeholder}
                  options={selectOptions}
                  loading={selectLoading}
                  optionLabelProp="label"
                  disabled={disabled}
                  showSearch={false}
                  getPopupContainer={trigger => trigger.parentNode}
                />
              ) : (
                <OTPSelect
                  options={
                    isAuthorizedField
                      ? field.url.includes('technicians_authorized')
                        ? authorizedTechnicians
                        : rnList
                      : technicians
                  }
                  loading={false}
                  placeholder="Select an option"
                  onChange={value => {
                    if (isBulk) {
                      bulkForm.setFieldsValue({
                        [field.label]: value,
                      });
                    } else {
                      form.setFieldsValue({
                        [field.key]: value,
                      });
                    }
                  }}
                  value={
                    isBulk
                      ? bulkForm.getFieldValue(field.label)
                      : form.getFieldValue(field.key)
                  }
                  field={field}
                  form={isBulk ? bulkForm : form}
                  disabled={disabled}
                  isBulk={isBulk}
                />
              );
            })()}
          </Form.Item>
        );
      case 'time':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              isRequired: isBulk,
              ...field.rules,
            })}
            name={isBulk ? field.label : field.key}
            key={field.name}
            style={{width: '100%'}}>
            <TimePicker
              use12Hours
              format="h:mm:A"
              placeholder={field?.placeholder}
              needConfirm
              onChange={() => setFirstLoad(false)}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 'switch':
        return (
          <Form.Item
            name={isBulk ? field.label : field.key}
            key={field.name}
            style={{width: '100%'}}
            valuePropName="checked"
            className={`flex items-center justify-${field.align} w-full`}>
            <Switch disabled={disabled} onChange={() => setFirstLoad(false)} />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={isBulk ? field.label : field.key}
            key={field.name}
            style={{width: '100%'}}>
            <DatePicker
              onChange={() => setFirstLoad(false)}
              format={'YYYY-MM-DD'}
              disabled={disabled}
            />
          </Form.Item>
        );
      case 'shorttext':
        return (
          <Form.Item
            rules={inputFieldRule({
              name: field.name,
              isWhiteSpace: false,
              ...field.rules,
            })}
            name={isBulk ? field.label : field.key}
            key={field.name}>
            <Input
              className="custom-input text-center"
              placeholder={field?.placeholder}
              disabled={disabled}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  const getMaxRow = procedures =>
    Math.max(...procedures.flatMap(p => p.fields.map(f => f.row)));

  const getMaxColAll = steps => {
    let maxCol = 0;
    steps.forEach(step => {
      step.procedures.forEach(procedure => {
        procedure.fields.forEach(field => {
          if (field.column > maxCol) {
            maxCol = field.column;
          }
        });
      });
    });
    return maxCol;
  };
  const initialValues = {}; // Create an object to hold initial values
  const initialBulkValues = {};
  // Assuming formSchema is structured to access fields

  const autoFillSubmissionFields = () => {
    if (!disabledKeys || disabledKeys.length === 0) {
      return; // Exit function if disabledKeys is still empty
    }
    formSchema?.steps?.forEach(step => {
      step.procedures.forEach(procedure => {
        procedure.fields.forEach(field => {
          if (disabledKeys?.includes(field.key)) {
            return; // if disable field return
          } else {
            console.log('Else AUTO FILL');
            const isRNField = field?.url?.includes('authorized');
            const isTechnicianField = field?.url?.includes('technicians');
            const isDefaultField =
              field?.rules?.default !== undefined ||
              field?.rules?.default !== 'false';
            if (
              isRNField &&
              Array.isArray(userData?.role) &&
              userData.role.includes(4) &&
              isDefaultField
            ) {
              let isAlreadyFilled =
                formPayload?.[step.key]?.[field.key] !== undefined ||
                payload?.[step.key]?.[field.key] !== undefined;
              if (isAlreadyFilled) return;
              initialValues[field.key] = userData?.id?.toString();
              initialBulkValues[field.label] = userData?.id?.toString();
              return;
            }
            if (
              isTechnicianField &&
              Array.isArray(userData?.role) &&
              userData.role.includes(3) &&
              isDefaultField
            ) {
              let isAlreadyFilled =
                formPayload?.[step.key]?.[field.key] !== undefined ||
                payload?.[step.key]?.[field.key] !== undefined;
              if (isAlreadyFilled) return;
              initialValues[field.key] = userData?.id?.toString();
              initialBulkValues[field.label] = userData?.id?.toString();
              return;
            }
            if (isDefaultField && !isRNField && !isTechnicianField) {
              initialValues[field.key] = field?.rules?.default; // Assign initial value
              initialBulkValues[field.label] = field?.rules?.default; // Assign initial value
            }
          }
        });
      });
    });
  };

  const sortedFormSchema =
    formSchema?.steps &&
    Array.isArray(formSchema.steps) &&
    [...formSchema.steps] // Create a shallow copy of the array
      .sort((a, b) => a.number - b.number);

  console.log(firstLoad, formPayload, 'firstLoad');
  const getDisabledKeys = () => {
    let disableKeys = [];
    let stepFoundIncomplete = false;
    let skipNextDisable = false; // New flag to skip disabling the next step
    let formsPayload = firstLoad ? formPayload : payload;

    // Define allowed types for which we want to disable fields
    const allowedFieldTypes = [
      'checkbox',
      'number',
      'textarea',
      'select',
      'time',
      'switch',
      'date',
      'shorttext',
    ];

    // Get step keys
    const stepKeys = sortedFormSchema?.map(step => step.key);

    // Check if any steps are completed
    const completedSteps = stepKeys?.filter(
      stepKey => formsPayload[stepKey]?.completedOn,
    );

    const areAllFieldsFilled = stepKey => {
      const step = sortedFormSchema.find(s => s.key === stepKey);

      return step.procedures.every(procedure =>
        procedure.fields.every(field => {
          const fieldValue = formsPayload[stepKey]?.[field.key];
          if (allowedFieldTypes.includes(field.type)) {
            // Check if the field is filled
            return (
              fieldValue !== undefined &&
              fieldValue !== null &&
              fieldValue !== '' &&
              !(field.type === 'checkbox' && fieldValue === false)
            );
          }
          return true; // Non-interactive fields like headings are always considered complete
        }),
      );
    };

    // If no steps are completed, enable only the first step
    if (completedSteps?.length === 0) {
      const firstStepKeys = sortedFormSchema[0]?.procedures.flatMap(procedure =>
        procedure.fields
          .filter(field => allowedFieldTypes.includes(field.type))
          .map(field => field.key),
      );
      disableKeys = stepKeys
        .slice(1)
        .flatMap(stepKey =>
          sortedFormSchema
            .find(step => step.key === stepKey)
            ?.procedures.flatMap(procedure =>
              procedure.fields
                .filter(field => allowedFieldTypes.includes(field.type))
                .map(field => field.key),
            ),
        );
    } else {
      // Iterate over steps to determine which fields to enable/disable
      stepKeys?.forEach((stepKey, index) => {
        const isStepComplete = formsPayload[stepKey]?.completedOn;
        const isOptionalStep = sortedFormSchema?.find(
          x => x.key === stepKey,
        )?.optional;

        if (isStepComplete) {
          if (isOptionalStep) {
            if (!areAllFieldsFilled(stepKey)) {
              return;
            }
          }
          // Disable fields for the completed step or optional step where all fields are filled
          const completedStepKeys = sortedFormSchema
            ?.find(x => x.key === stepKey)
            ?.procedures.flatMap(procedure =>
              procedure.fields
                .filter(field => allowedFieldTypes.includes(field.type))
                .map(field => field.key),
            );

          if (completedStepKeys) {
            disableKeys.push(...completedStepKeys);
          }
        } else if (!stepFoundIncomplete) {
          // Enable the first incomplete step
          stepFoundIncomplete = true;

          // If this step is optional, enable the next step as well
          if (isOptionalStep && index < stepKeys.length - 1) {
            const nextStepKey = stepKeys[index + 1];
            const nextStepFields = sortedFormSchema
              ?.find(step => step.key === nextStepKey)
              ?.procedures.flatMap(procedure =>
                procedure.fields
                  .filter(field => allowedFieldTypes.includes(field.type))
                  .map(field => field.key),
              );

            if (nextStepFields) {
              disableKeys = disableKeys.filter(
                key => !nextStepFields.includes(key),
              ); // Ensure the next step's fields are enabled
            }

            // Skip disabling the next step
            skipNextDisable = true;
          }
        } else if (!skipNextDisable) {
          // Disable fields for any subsequent steps
          const subsequentStepKeys = sortedFormSchema
            ?.find(x => x.key === stepKey)
            ?.procedures.flatMap(procedure =>
              procedure.fields
                .filter(field => allowedFieldTypes.includes(field.type))
                .map(field => field.key),
            );

          if (subsequentStepKeys) {
            disableKeys.push(...subsequentStepKeys);
          }
        } else {
          // Reset skipNextDisable flag after skipping one step
          skipNextDisable = false;
        }
      });
    }

    setDisabledKeys(disableKeys);
  };
  useEffect(() => {
    if (formSchema?.is_sequential !== 'false') {
      getDisabledKeys();
      console.log('Disable keys', disabledKeys);
    }
  }, [formSchema, payload]);

  useEffect(() => {
    if (firstLoad)
      evaluateDependencies(form.getFieldsValue(), form.getFieldsValue(), false);
  }, []);

  const prevDisabledKeysRef = useRef([]); // Initialize to an empty array

  useEffect(() => {
    // Compare the new value with the previous one
    if (
      JSON.stringify(disabledKeys) !==
      JSON.stringify(prevDisabledKeysRef.current)
    ) {
      checkCompletedSteps();
    }
    // Update the previous value
    prevDisabledKeysRef.current = disabledKeys;
  }, [disabledKeys]);

  useEffect(() => {
    autoFillSubmissionFields();
  }, [payload, formSchema, form, bulkForm]);
  autoFillSubmissionFields();

  const clearBulkFormDisabledKeys = () => {
    let bulkFormKeys = Object.keys(bulkForm.getFieldsValue());
    console.log(bulkFormKeys, 'disabledKeys');
    setDisabledKeys(prevDisabledKeys =>
      prevDisabledKeys.filter(key => !bulkFormKeys.includes(key)),
    );
  };

  // const bulkFormValues = Object.keys(bulkForm.getFieldsValue());
  // const bulkFormValuesRef = useRef([]); // Initialize to an empty array

  // useEffect(() => {
  //   if (
  //     JSON.stringify(bulkFormValues) !==
  //     JSON.stringify(bulkFormValuesRef.current)
  //   )
  //     clearBulkFormDisabledKeys();
  //   // bulkForm.resetFields();
  //   bulkFormValuesRef.current = bulkFormValues;
  // }, [bulkFormValues]);

  // console.log(disabledKeys, 'disabledKeys');

  // Optional logic
  const handleSubmit = vals => {
    submitForm({
      payload: {...payload, Submitted: true},
      pathParams: id,
      logic(res) {
        dispatch(blankOptionalSteps());
        toastAlert('Form Submitted Successfully.');
        navigate('/');
      },
    });
  };

  const handleStepStart = (stepNumber, isAdding) => {
    if (completedSteps?.includes(stepNumber)) {
      console.log('Step Completed Already');
      return;
    } else {
      console.log(`Step Number: ${stepNumber}, Adding: ${isAdding}`);
      dispatch(setOptionalSteps({stepNumber, isAdding}));
      if (!optionalSecAdded) {
        setOptionalSecAdded(true); // Enable validation for optional steps
      }
    }
  };

  const handleCollapseHeader = stepNumber => {
    console.log('Number', stepNumber);
    // dispatch(setOptionalSteps(stepNumber));
    // if (!optionalSecAdded) {
    //   setOptionalSecAdded(true); // Enable validation for optional steps
    // }
  };

  const isButtonDisabled = () => {
    if (optionalSecAdded === false) {
      // Only non-optional steps are required
      return (
        completedSteps?.length !==
          formSchema?.steps?.filter(step => step.optional === false).length ||
        isFormCompleted
      );
    } else {
      // Both optional and non-optional steps are required
      const optAndNonOpt =
        formSchema?.steps?.filter(step => step.optional === false).length +
        optionalSteps.length;
      console.log(
        'Opt&NonOpt',
        optAndNonOpt,
        'Opt',
        optionalSteps.length,
        'NonOpt',
        formSchema?.steps?.filter(step => step.optional === false).length,
        'Comp Steps len',
        completedSteps?.length,
      );
      return completedSteps?.length !== optAndNonOpt || isFormCompleted;
    }
  };
  console.log('Completed Steps', completedSteps);

  return (
    <>
      <div className="absolute top-[0] w-full flex items-center justify-between pl-7 pr-20 py-[11px] bg-grey-100 border-b-[1px] border-solid border-grey-200 z-[1] ">
        <div className="flex items-center justify-start gap-[25px]">
          <Icons.LeftChevron
            className="cursor-pointer icon"
            onClick={() => navigate(-1)}
          />
          <p className="font-h2 uppercase ">
            {formSchema?.procedure_title || 'Form'}
          </p>
        </div>
        <div
          className="flex items-center justify-start gap-[10px] cursor-pointer form-header-options "
          onClick={handlePrint}>
          <Icons.PrintIcon height={24} width={24} />
          <p className="font-roboto font-medium text-lg text-blue-300">Print</p>
        </div>
      </div>
      <div className="custom-step-from">
        {uiLoader ? (
          <Loader height="80vh" />
        ) : (
          <>
            <div className="flex items-center justify-start py-[11px] bg-white-100 border-b-[1px] border-solid border-grey-200 ">
              {formSchema?.columns?.map((item, index) =>
                item.title === 'Done' ? (
                  <div
                    key={index}
                    className={`w-[${item.width}] flex items-center justify-${item.align}`}>
                    <p className="font-h3">
                      {item.title}: {completedSteps?.length} of{' '}
                      {/* {formSchema?.steps?.filter(step => !step.optional).length} */}
                      {formSchema?.steps?.length}
                    </p>
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`w-[${item.width}] flex items-center justify-${item.align}`}>
                    <p className="font-h3">{item.title}</p>
                  </div>
                ),
              )}
            </div>
            {isBulkEntryForm && (
              <div className="flex justify-end p-2 ">
                <Button
                  style={{
                    backgroundColor: '#004078',
                    color: '#fff',
                    borderRadius: '5px',
                  }}
                  type="primary"
                  className="disabled:opacity-50"
                  disabled={isFormCompleted}
                  onClick={() => {
                    showBulkModal();
                  }}>
                  Bulk Entry
                </Button>
              </div>
            )}
            <Form
              form={form}
              layout="vertical"
              onFieldsChange={(changedFields, allFields) => {
                handleValuesChange(changedFields, allFields);
              }}
              onKeyUp={() => setFirstLoad(false)}
              className="pb-[90px] relative"
              onFinish={handleSubmit}
              initialValues={initialValues}
              disabled={isFormCompleted}>
              {isFormCompleted && (
                <div className="absolute z-[2] top-0 left-0 w-full h-full bg-grey-100 opacity-30"></div>
              )}

              {/* STEPS RENDER */}
              {formSchema?.steps
                ?.slice() // Create a shallow copy to avoid modifying the original array
                ?.sort((a, b) => a.number - b.number) // Now sort safely
                ?.map(step =>
                  step.optional ? (
                    <Collapse
                      defaultActiveKey={commonKeyIds}
                      destroyInactivePanel={false} // Keep content mounted
                      expandIcon={({isActive}) =>
                        isActive ? (
                          <Icons.DeleteIconDisabled
                            style={{
                              fontSize: '24px',
                              marginTop: '20%',
                            }}
                            onClick={e => {
                              //e.stopPropagation(); // Prevent default collapse toggle
                              handleStepStart(step.number, false); // Remove step
                            }}
                          />
                        ) : (
                          <PlusCircleTwoTone
                            style={{fontSize: '24px', marginTop: '20%'}}
                            onClick={e => {
                              //e.stopPropagation(); // Prevent default collapse toggle
                              handleStepStart(step.number, true); // Add step
                            }}
                          />
                        )
                      }
                      className="">
                      <Panel
                        header={
                          <div
                            className="flex items-center w-full"
                            onClick={() => handleCollapseHeader(step.number)}>
                            <span className="mr-[25px]">{step.number}</span>
                            <span className="w-[91%]">{step.title}</span>
                            <div className="w-[17%] flex items-center justify-center">
                              {completedSteps?.includes(step?.number) ? (
                                <Icons.Checked height={24} width={24} />
                              ) : (
                                <Icons.CheckedDisabled height={24} width={24} />
                              )}
                            </div>
                          </div>
                        }
                        key={step.id}
                        className="font-h3 custom-collapse-header">
                        <div
                          key={step.id}
                          className="flex items-center justify-start py-[20px] bg-white-100 border-b-[1px] border-solid border-grey-200">
                          <div className="w-[10%] flex items-center justify-center">
                            <p className="font-h3">{step.number}</p>
                          </div>
                          <div className="w-[73%] flex items-center justify-start">
                            <div className="w-full">
                              <Row className="w-[100%]" gutter={[0, 10]}>
                                {Array.from({
                                  length: getMaxRow(step.procedures),
                                }).map((_, rowIndex) => (
                                  <Col span={24} key={`row-${rowIndex}`}>
                                    <Row gutter={[20, 10]}>
                                      {Array.from({
                                        length: getMaxColAll(formSchema.steps),
                                      }).map((_, colIndex) => (
                                        <Col
                                          className="flex items-center"
                                          span={
                                            24 / getMaxColAll(formSchema.steps)
                                          }
                                          key={`col-${colIndex}`}>
                                          {step.procedures.map(procedure =>
                                            procedure.fields.map(
                                              (field, fieldIndex) => {
                                                if (
                                                  field.row === rowIndex + 1 &&
                                                  field.column === colIndex + 1
                                                ) {
                                                  return (
                                                    <React.Fragment
                                                      key={fieldIndex}>
                                                      {field.type ===
                                                        'heading' ||
                                                      field.type === 'text' ? (
                                                        <p
                                                          className={`w-full font-${field?.style?.toLowerCase()} text-${
                                                            field.align
                                                          } mb-[24px]`}>
                                                          {field.text}
                                                        </p>
                                                      ) : (
                                                        <div
                                                          className="w-full"
                                                          // onFocus={() =>
                                                          //   handleStepStart(
                                                          //     step.number,
                                                          //   )
                                                          // }
                                                        >
                                                          {renderField(field)}
                                                        </div>
                                                        // renderField(field)
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                }
                                                return null;
                                              },
                                            ),
                                          )}
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  ) : (
                    <div
                      key={step.id}
                      className="flex items-center justify-start py-[20px] bg-white-100 border-b-[1px] border-solid border-grey-200">
                      <div className="w-[10%] flex items-center justify-center">
                        <p className="font-h3">{step.number}</p>
                      </div>
                      <div
                        className={`w-[73%] flex items-center justify-start`}>
                        <div className="w-full">
                          {step?.visibility ? (
                            <p className="font-h3 mb-[20px]">{step.title}</p>
                          ) : (
                            <></>
                          )}
                          <Row className="w-[100%]" gutter={[0, 10]}>
                            {Array.from({
                              length: getMaxRow(step.procedures),
                            }).map((_, rowIndex) => (
                              <Col span={24} key={`row-${rowIndex}`}>
                                <Row gutter={[20, 10]}>
                                  {Array.from({
                                    length: getMaxColAll(formSchema.steps),
                                  }).map((_, colIndex) => (
                                    <Col
                                      className="flex items-center"
                                      span={24 / getMaxColAll(formSchema.steps)}
                                      key={`col-${colIndex}`}>
                                      {step.procedures.map(procedure =>
                                        procedure.fields.map(
                                          (field, fieldIndex) => {
                                            if (
                                              field.row === rowIndex + 1 &&
                                              field.column === colIndex + 1
                                            ) {
                                              return (
                                                <React.Fragment
                                                  key={fieldIndex}>
                                                  {field.type === 'heading' ||
                                                  field.type === 'text' ? (
                                                    <p
                                                      className={`w-full font-${field?.style?.toLowerCase()} text-${
                                                        field.align
                                                      } mb-[24px]`}>
                                                      {field.text}
                                                    </p>
                                                  ) : (
                                                    renderField(field)
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                            return null;
                                          },
                                        ),
                                      )}
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                      <div className="w-[17%] flex items-center justify-center">
                        {completedSteps?.includes(step?.number) ? (
                          <Icons.Checked height={24} width={24} />
                        ) : (
                          <Icons.CheckedDisabled height={24} width={24} />
                        )}
                      </div>
                    </div>
                  ),
                )}
            </Form>

            <div
              className={`footer fixed bottom-[0] pl-[20px] pr-[60px] lg:pr-[120px] h-[90px] w-full flex items-center ${
                isFormCompleted ? 'justify-between z-[4]' : 'justify-end'
              } bg-grey-100 gap-[50px] border-t-[1px] border-solid border-grey-200`}>
              {isFormCompleted && (
                <div className="flex items-center gap-4 ">
                  <span className="text-[18px] font-semibold text-[#004078]">
                    Today’s log for this form is already submitted.
                  </span>
                </div>
              )}
              <div className="flex items-center justify-end gap-[50px]">
                {/* <Button
                  className="custom-form-btn"
                  onClick={() => {
                    form.resetFields();
                    createPayload(true);
                    setCompletedSteps([]);
                    setIsAnyFieldFilled(false);
                  }}
                  disabled={!isAnyFieldFilled || isFormCompleted}>
                  Reset All
                </Button> */}
                {isFormCompleted && (
                  <Button
                    className="custom-form-btn primary"
                    onClick={handleEdit}
                    htmlType="submit"
                    loading={submitFormLoader}>
                    Reopen Log
                  </Button>
                )}
                <Button
                  className="custom-form-btn primary"
                  disabled={
                    isButtonDisabled()
                    // completedSteps?.length !== formSchema?.steps?.length ||
                    // isFormCompleted
                    // completedSteps?.length !==
                    //   formSchema?.steps?.filter(step => step.optional === false)
                    //     .length || isFormCompleted
                  }
                  onClick={handleSubmit}
                  htmlType="submit"
                  loading={submitFormLoader}>
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
        {isBulkEntryForm && (
          <Modal
            title="Bulk Entry"
            open={isBulkModalOpen}
            onCancel={handleCancel}
            onOk={handleBulkSubmit}
            okText="Submit" // Change the text of the OK button
            okButtonProps={{
              style: {
                backgroundColor: '#004078',
                color: '#fff',
                borderRadius: '5px',
              }, // Customize the button style
            }}
            destroyOnClose
            width={800}>
            <Form
              form={bulkForm}
              layout="vertical"
              initialValues={initialBulkValues}
              onFieldsChange={(changedFields, allFields) =>
                handleBulkFieldsChange(changedFields, allFields)
              }
              onValuesChange={changedValues =>
                setBulkValues(prev => ({...prev, ...changedValues}))
              }>
              {/* Multi-Select Dropdown */}
              <Select
                placeholder="Select Machines"
                mode="multiple"
                value={selectedMachines}
                onChange={setSelectedMachines}
                options={formSchema?.steps
                  ?.filter(
                    step =>
                      step?.title?.toLowerCase().includes('machine') ||
                      step?.title?.toLowerCase().includes('station'),
                  ) // Filter titles containing "machine"
                  ?.map(step => ({
                    value: step?.key,
                    label: step?.title,
                  }))}
                style={{width: '100%', marginBottom: 20}}
                maxTagCount={3}
                showSearch={false}
                getPopupContainer={trigger => trigger.parentNode}
              />
              {selectedMachines?.length > 0 && (
                <div className="flex justify-start overflow-auto max-w-full gap-5 mb-[10px] py-[10px] hide-scrollbar">
                  {selectedMachines?.map((item, index) => (
                    <div
                      key={index}
                      className="w-max whitespace-nowrap px-3 py-2 rounded-md relative flex items-center justify-center bg-blue-300 text-white-100 text-[14px] font-medium">
                      {formSchema?.steps?.find(x => x?.key === item)?.title}
                      <div
                        onClick={() => removeSelectedMachine(item)}
                        className="w-[14px] h-[14px] cursor-pointer p-[1px] rounded-full absolute top-[-5px] right-[-5px] bg-textColor-200 flex items-center justify-center">
                        <X size={16} />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* Render dynamic fields */}
              {formSchema?.steps?.[0]?.procedures?.flatMap(procedure =>
                procedure?.fields?.map(field => renderField(field, true)),
              )}
            </Form>
          </Modal>
        )}
      </div>
      <ConfirmationModal
        open={confirmationModalOpen}
        title="Reopen Log for Editing?"
        description={`Are you sure you want to reopen this log? This action will allow you to add new entries. You must resubmit the log after making changes to ensure compliance.`}
        handleClose={() => {
          setConfirmationModalOpen(false);
        }}
        onConfirm={handleUpdateLogStatus}
      />
      ;
    </>
  );
};

export default OptionalFormComponent;
