import {createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
const UserReducer = createSlice({
  name: 'user',
  initialState: {
    data: {},
    isAuthenticated: false,
    access_token: '',
    userData: {},
    companies: [],
  },
  reducers: {
    // USER LOGIN
    loginRequest() {},
    loginSuccess(state, action) {
      console.log(action.payload, '_access_token');
      Cookies.set('access_token', action.payload.access_token, {
        // expire token in 15 minutes
        expires: 1 / 96,
        secure: true,
        sameSite: 'Strict',
      });
      state.isAuthenticated = true;
      state.data = action.payload;
    },

    // USER SIGNOUT
    logoutRequest(state, action) {},
    logoutSuccess(state, action) {
      state.isAuthenticated = false;
      state.data = {};
      state.userData = {};
    },

    //USER DATA
    getUserDataRequest(state, action) {},
    getUserDataSuccess(state, action) {
      state.userData = action.payload;
    },

    //USER COMPANIES
    getUserCompaniesRequest(state, action) {},
    getUserCompaniesSuccess(state, action) {
      state.companies = action.payload;
    },

    //CHANGE COMPANY
    changeCompanyRequest(state, action) {
      console.log(action.payload, 'actionPayload');
      //   let temp = state.data;
      //   temp.companyId = action.payload.payload;
      //   state.data = temp;

      // Extracting companyId and roles from payload
      const {value: companyId, role} = action.payload.payload;

      // Updating data with new companyId and roles
      state.data = {
        ...state.data,
        companyId,
        role, // Store roles in data
      };

      // Updating userData with new roles
      state.userData = {
        ...state.userData,
        role, // Store roles in userData as well
      };
    },

    //VERIFY USER PIN
    verifyPinRequest() {},
  },
});

export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  getUserDataRequest,
  getUserDataSuccess,
  getUserCompaniesRequest,
  getUserCompaniesSuccess,
  changeCompanyRequest,
  verifyPinRequest,
} = UserReducer.actions;

export default UserReducer.reducer;
