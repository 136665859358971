import React, {useEffect, useState} from 'react';
import './styles.scss';
import {Icons} from '../../../theme';
import {Typography} from '@mui/material';
import Clock from '../clock';
import {useLocation, useNavigate} from 'react-router-dom';
import SearchDrawer from '../searchDrawer';
import EditProfileModal from '../editProfileModal';
import {useDispatch, useSelector} from 'react-redux';
import {
  changeCompanyRequest,
  getUserCompaniesRequest,
  logoutRequest,
} from '../../../redux/slicers/user';
import MemberManagementModal from '../memberManagementModal';
import {CustomDispatch} from '../../../helpers';
import {ClipLoader} from 'react-spinners';
import {Col, Drawer, Popover, Row, Select, Tooltip} from 'antd';
import Cookies from 'js-cookie';
import NotificationDrawer from '../notificationDrawer';
import {Menu, X} from 'lucide-react';

function PrivateHeader() {
  //STATES
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [memberManagementModalOpen, setMemberManagementModalOpen] =
    useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [responsiveDrawerOpen, setResponsiveDrawerOpen] = useState(false);

  //REDUX DATA
  const {data, companies} = useSelector(state => state.user);
  const {isOnline} = useSelector(state => state.offline);

  //CUSTOM DISPATCH
  const [logout, logoutLoader] = CustomDispatch(logoutRequest);
  const [getCompanies, companiesLoader] = CustomDispatch(
    getUserCompaniesRequest,
  );
  const [changeCompany] = CustomDispatch(changeCompanyRequest);

  //CONST VALS
  const {pathname} = useLocation();
  const navigate = useNavigate();
  //HANDLERS
  const searchDrawerOpenHandler = () => {
    setSearchDrawerOpen(!searchDrawerOpen);
  };
  const profileModalOpenHandler = () => {
    setProfileModalOpen(!profileModalOpen);
  };
  const memberManagementModalOpenHandler = () => {
    setMemberManagementModalOpen(!memberManagementModalOpen);
  };
  const handleLogout = () => {
    logout();
    Cookies.remove('access_token');
  };

  const handleCompanyChange = value => {
    const selectedCompany = companies.find(company => company.value === value);
    const role = selectedCompany ? selectedCompany.roles : [];
    console.log(`Selected Company Value: ${value}, Roles:`, role);
    //changeCompany({payload: value});
    changeCompany({payload: {value, role}});
  };
  const responsiveDrawerOpenHandler = () => {
    setResponsiveDrawerOpen(!responsiveDrawerOpen);
  };
  //HOOKS
  useEffect(() => {
    if (data?.id) getCompanies({payload: {user_id: data?.id}});
  }, [data]);
  console.log(pathname, 'companies');

  const disableCompanyDropdown =
    pathname === '/' ||
    pathname === '/compliance-rules' ||
    pathname === '/today-submissions';
  return (
    <>
      {isDropdownOpen && (
        <div
          className="fixed w-screen h-screen z-10"
          onClick={() => setIsDropdownOpen(false)}></div>
      )}
      <div
        className={`fixed z-20 right-1 overflow-hidden ${
          isDropdownOpen ? 'top-[73px]' : 'opacity-0 top-[-250px]'
        } border bg-white-100 rounded-md border-blue-300  w-[220px]  transition-top duration-500 ease-in-out `}>
        <div
          className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
          onClick={() => {
            profileModalOpenHandler();
            setIsDropdownOpen(false);
          }}>
          <Icons.ProfileIcon height={16} width={16} />
          <p className="text-[14px]">Profile</p>
        </div>
        {data?.isAdmin && (
          <>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                memberManagementModalOpenHandler();
                setIsDropdownOpen(false);
              }}>
              <Icons.MembersIcon height={16} width={16} />
              <p className="text-[14px]">Member Management</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                navigate('/compliance-rules');
                setIsDropdownOpen(false);
              }}>
              <Icons.NotificationIcon height={16} width={16} />
              <p className="text-[14px]">Notification Settings</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                navigate('/today-submissions');
                setIsDropdownOpen(false);
              }}>
              <Icons.ProcedureIcon height={16} width={16} />
              <p className="text-[14px]">Today's Logs</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                navigate('/support-tickets');
                setIsDropdownOpen(false);
              }}>
              <Icons.HelpIcon height={16} width={16} />
              <p className="text-[14px]">Support Tickets</p>
            </div>
            <div
              className="flex items-center justify-start gap-2 p-4 py-2 border-b-[1px] border-[#D5DFE6] hover:bg-[#E2F5FF] cursor-pointer"
              onClick={() => {
                setIsDropdownOpen(false);
              }}>
              <Icons.SettingsIcon height={16} width={16} />
              <p className="text-[14px]">System Settings</p>
            </div>
          </>
        )}
        <div
          className="flex items-center justify-start gap-2 p-4 py-2 hover:bg-[#E2F5FF] cursor-pointer h-[38px]"
          onClick={() => {
            setIsDropdownOpen(false);
            handleLogout();
          }}>
          {logoutLoader ? (
            <div className="flex items-center justify-center w-full ">
              <ClipLoader size={12} color="#004078" />
            </div>
          ) : (
            <>
              <Icons.ExitIcon height={16} width={16} />
              <p className="text-[14px]">Log Out</p>
            </>
          )}
        </div>
        {/* Dropdown content */}
      </div>

      <div
        onClick={() => setIsDropdownOpen(false)}
        className="private-header z-30 flex justify-between gap-4 items-center w-full px-9 py-3">
        <div className="flex items-center	justify-start gap-2">
          <Icons.EberLogo
            className="cursor-pointer"
            onClick={() => navigate('/')}
          />
          <Typography
            className="select-none cursor-pointer"
            fontFamily={'Arial'}
            fontWeight={700}
            fontSize={30}
            onClick={() => navigate('/')}>
            EBER
          </Typography>
          {companies.length > 1 && (
            <div
              className={`${
                pathname.includes('/form/') ? 'min-[1142px]:flex hidden' : ''
              }`}>
              <Select
                options={companies}
                loading={companiesLoader}
                disabled={companiesLoader || !disableCompanyDropdown}
                defaultValue={data?.companyId}
                onChange={handleCompanyChange}
                // options={[{label: 'Admin', value: 1}]}
                placeholder="Select Clinic"
              />
            </div>
          )}
        </div>
        {pathname.includes('/form/') && <Clock />}
        <div className="flex items-center	justify-start gap-[30px]">
          <NotificationDrawer />
          <Icons.SearchIcon
            className="cursor-pointer header-icons"
            height={24}
            width={24}
            onClick={searchDrawerOpenHandler}
          />
          {/* <Icons.ExitIcon
          className="cursor-pointer header-icons"
          height={24}
          width={24}
        /> */}
          <div
            onClick={e => {
              e.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }}
            className="min-[1142px]:flex hidden items-center justify-start gap-2 hover:text-[#65a6cc] text-[14px] text-blue-300 font-semibold cursor-pointer profile-name">
            <Icons.UserIcon className="cursor-pointer" height={24} width={24} />
            {data?.name}
            <div
              className="w-[10px] h-[10px] rounded-full"
              style={{
                backgroundColor: isOnline ? '#00FF00' : '#FF0000',
              }}></div>
          </div>
          <Popover
            trigger={['click']}
            title="Info"
            placement="bottomRight"
            content={
              <div>
                <p>Version: {process.env.REACT_APP_VERSION}</p>
              </div>
            }
            getPopupContainer={triggerNode => triggerNode.parentNode}>
            <Icons.InfoIcon
              width={20}
              height={20}
              className="cursor-pointer header-icons min-[1142px]:flex hidden"
            />
          </Popover>
          <div className="min-[1142px]:hidden flex items-center justify-center">
            {responsiveDrawerOpen ? (
              <X
                size={30}
                color="#004078"
                strokeWidth={3.5}
                absoluteStrokeWidth
                className="cursor-pointer"
                onClick={responsiveDrawerOpenHandler}
              />
            ) : (
              <Menu
                size={30}
                color="#004078"
                strokeWidth={3.5}
                absoluteStrokeWidth
                className="cursor-pointer"
                onClick={responsiveDrawerOpenHandler}
              />
            )}
            <Drawer
              width={350}
              open={responsiveDrawerOpen}
              closeIcon={false}
              zIndex={1}
              destroyOnClose
              placement="right"
              className="common-drawer !relative "
              maskClosable>
              <Row gutter={[0, 15]} className="mt-[69px]">
                <Col span={24}>
                  <div className="flex items-center justify-start gap-2 pb-[30px] text-[14px] text-blue-300 font-semibold cursor-pointer profile-name">
                    <div className="relative flex items-center justify-center">
                      <Icons.UserIcon
                        className="cursor-pointer"
                        height={24}
                        width={24}
                      />
                      <div
                        className="w-[10px] absolute bottom-[-2px] right-[-2px] h-[10px] rounded-full self-start"
                        style={{
                          backgroundColor: isOnline ? '#00FF00' : '#FF0000',
                        }}></div>
                    </div>
                    <div>
                      <p>{data?.name}</p>
                      <p className="font-normal  text-[10px]">
                        {
                          companies?.find(x => x?.value === data?.companyId)
                            ?.label
                        }
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className="flex items-center justify-start gap-2  py-2 border-b-[1px] border-[#D5DFE6]  cursor-pointer"
                    onClick={() => {
                      profileModalOpenHandler();
                      setResponsiveDrawerOpen(false);
                    }}>
                    <Icons.ProfileIcon height={22} width={22} />
                    <p className="text-[16px]">Profile</p>
                  </div>
                </Col>
                {data?.isAdmin && (
                  <>
                    <Col span={24}>
                      <div
                        className="flex items-center justify-start gap-2 py-2 border-b-[1px] border-[#D5DFE6] cursor-pointer"
                        onClick={() => {
                          memberManagementModalOpenHandler();
                          setResponsiveDrawerOpen(false);
                        }}>
                        <Icons.MembersIcon height={22} width={22} />
                        <p className="text-[16px]">Member Management</p>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div
                        className="flex items-center justify-start gap-2 py-2 border-b-[1px] border-[#D5DFE6]  cursor-pointer"
                        onClick={() => {
                          navigate('/compliance-rules');
                          setResponsiveDrawerOpen(false);
                        }}>
                        <Icons.NotificationIcon height={22} width={22} />
                        <p className="text-[16px]">Notification Settings</p>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div
                        className="flex items-center justify-start gap-2 py-2 border-b-[1px] border-[#D5DFE6] cursor-pointer"
                        onClick={() => {
                          navigate('/today-submissions');
                          setResponsiveDrawerOpen(false);
                        }}>
                        <Icons.ProcedureIcon height={22} width={22} />
                        <p className="text-[16px]">Today's Logs</p>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div
                        className="flex items-center justify-start gap-2 py-2 border-b-[1px] border-[#D5DFE6] cursor-pointer"
                        onClick={() => {
                          setResponsiveDrawerOpen(false);
                        }}>
                        <Icons.SettingsIcon height={22} width={22} />
                        <p className="text-[16px]">System Settings</p>
                      </div>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <div
                    className="flex items-center justify-start gap-2  py-2  cursor-pointer h-[38px]"
                    onClick={() => {
                      handleLogout();
                      setResponsiveDrawerOpen(false);
                    }}>
                    {logoutLoader ? (
                      <div className="flex items-center justify-center w-full ">
                        <ClipLoader size={18} color="#004078" />
                      </div>
                    ) : (
                      <>
                        <Icons.ExitIcon height={22} width={22} />
                        <p className="text-[16px]">Log Out</p>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <p className="absolute bottom-[20px] right-[20px]">
                Version: {process.env.REACT_APP_VERSION}
              </p>
            </Drawer>
          </div>
        </div>
        <SearchDrawer
          open={searchDrawerOpen}
          handleClose={searchDrawerOpenHandler}
        />
        <EditProfileModal
          open={profileModalOpen}
          handleClose={profileModalOpenHandler}
        />
        <MemberManagementModal
          open={memberManagementModalOpen}
          handleClose={memberManagementModalOpenHandler}
        />
      </div>
    </>
  );
}

export default PrivateHeader;
